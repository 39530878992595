//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-228:_8084,_100,_1708,_8508,_1444,_3836,_2980,_4500;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-228')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-228', "_8084,_100,_1708,_8508,_1444,_3836,_2980,_4500");
        }
      }catch (ex) {
        console.error(ex);
      }