//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-228:_7384,_4340,_876,_5977,_7168,_1820,_9464,_348;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-228')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-228', "_7384,_4340,_876,_5977,_7168,_1820,_9464,_348");
        }
      }catch (ex) {
        console.error(ex);
      }